<script setup lang="ts">
import appStore from '@/assets/footer/download_appstore-min.png'
import playStore from '@/assets/footer/download_playstore-min.png'
import {
  canInstallAsApp,
  checkBoxDisableInstallPrompt,
  deferredPrompt,
  disableInstallPrompt,
  showConfirmDisableInstallPrompt,
  showInstallPrompt,
  supportedInstallAsApp
} from '@/components/layout/InstallPromptComponent/InstallPromptCompose'
import { showManager } from '@/components/layout/ManageCookies/ManageCookiesCompose'
import { gamesUtils } from '@/compositions/gamesUtils'

import { isFirefox, isOpera } from 'mobile-device-detect'
import LogoSite from '/src/assets/logo.svg'

const { routeGame, isLogged } = gamesUtils

function onAdd() {
  checkBoxDisableInstallPrompt.value = false
  showConfirmDisableInstallPrompt.value = false
  disableInstallPrompt.value = false
  localStorage?.setItem?.('dip', '')
  if (canInstallAsApp.value) {
    deferredPrompt.value?.prompt?.()
  } else {
    showInstallPrompt.value = true
  }
}
</script>

<template>
  <div class="bg-main-footer px-4 py-8 big:px-8 big:py-10 tracking-wide mt-5 relative">
    <div class="max-w-screen-xl mx-auto flex flex-wrap">
      <div class="text-white font-semibold w-full">
        Download our games from:
      </div>
      <div class="mt-5 w-full">
        <a
          href="https://apps.apple.com/developer/gamesture-sp-z-o-o/id1079884679"
          target="_blank"
          rel="nofollow"
        >
          <el-image
            :src="appStore"
            title="download from app store"
            class="max-w-[45%] big:max-w-[140px]"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/dev?id=6838434049690937885"
          target="_blank"
          rel="nofollow"
        >
          <el-image
            :src="playStore"
            title="download from play store"
            class="ml-4 max-w-[45%] big:max-w-[140px]"
          />
        </a>
      </div>
      <hr class="border border-white mt-5 w-full">
      <ul class="text-white list-none w-full mt-2 mb-5">
        <li class="big:inline-block pt-4">
          <router-link
            :to="{ name: 'terms-of-service-page' }"
            class="pr-2 sm:pr-5"
            rel="nofollow"
          >
            Terms of service
          </router-link>
        </li>
        <li class="big:inline-block pt-4">
          <router-link
            :to="{ name: 'privacy-policy-page' }"
            class="pr-2 sm:pr-5"
            rel="nofollow"
          >
            Privacy Policy
          </router-link>
        </li>
        <li class="big:inline-block pt-4">
          <a
            class="pr-2 sm:pr-5 cursor-pointer"
            rel="nofollow"
            @click.prevent.stop="showManager = true"
          >Manage Cookies</a>
        </li>
      </ul>
      <div class="w-1/2 text-[#5c727a] py-5">
        Gamesture sp. z o.o. <br>
        Pawia 23 <br>
        31-154 Kraków
      </div>
      <div class="w-1/2 text-right py-5">
        <a
          href="https://gamesture.com/"
          target="_blank"
          rel="noreferrer"
        >
          <LogoSite class="text-white max-w-[80px] inline-block" />
        </a>
      </div>
      <div
        v-if="routeGame?.gameKey"
        class="absolute bottom-4 flex items-center gap-x-2"
      >
        <template
          v-if="isLogged && (!supportedInstallAsApp || (supportedInstallAsApp && !isOpera && !isFirefox && canInstallAsApp))"
        >
          <el-tooltip
            effect="dark"
            content="Add to home screen"
          >
            <icon-ify
              icon="ic:round-install-mobile"
              class="inline text-xl text-[#5c727a] hover:text-white cursor-pointer"
              @click="onAdd"
            />
          </el-tooltip>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
