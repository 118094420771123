<script setup lang="ts">
import HomeScreenSafariDesktop from '@/assets/components/installPromptComponent/desktop_safari.png'
import HomeScreenDesktop from '@/assets/components/installPromptComponent/desktop_win.png'
import HomeScreen from '@/assets/components/installPromptComponent/home_screen.png'
import {
  canInstallAsApp,
  checkBoxDisableInstallPrompt,
  deferredPrompt,
  disableInstallPrompt,
  showInstallPrompt
} from '@/components/layout/InstallPromptComponent/InstallPromptCompose'
import { isMobile, isSafari } from 'mobile-device-detect'

if (!import.meta.env.SSR) {
  const dip = localStorage?.getItem?.('dip') || ''
  if (dip === 'on') {
    disableInstallPrompt.value = true
  }
  if (window?.BeforeInstallPromptEvent) {
    window.addEventListener('appinstalled', () => {
      deferredPrompt.value = null
      showInstallPrompt.value = false
      canInstallAsApp.value = false
    })
  }
}
watch(
  () => showInstallPrompt.value,
  () => {
    if (showInstallPrompt.value) {
      document.body.classList.add('overflow-hidden')
    } else {
      checkBoxDisableInstallPrompt.value = false
      document.body.classList.remove('overflow-hidden')
    }
  }
)
watch(
  () => disableInstallPrompt.value,
  () => {
    if (disableInstallPrompt.value) {
      localStorage?.setItem?.('dip', 'on')
    }
  }
)

function gotIt() {
  showInstallPrompt.value = false
  if (checkBoxDisableInstallPrompt.value) {
    disableInstallPrompt.value = true
  }
}
</script>

<template>
  <transition name="toggle">
    <div
      v-if="showInstallPrompt"
      class="install-prompt fixed bg-black/90 overflow-hidden z-[999999]"
    >
      <div class="h-dvh flex justify-center items-center p-4">
        <div class="overflow-y-auto max-h-full min-w-10 max-w-full">
          <div class="rounded-2xl bg-white max-w-xl overflow-hidden">
            <div class="px-6 py-8 text-center relative">
              <button
                class="text-2xl transition hover:rotate-90 absolute right-1.5 top-1"
                @click="showInstallPrompt = false"
              >
                <icon-ify
                  icon="material-symbols:close"
                  class=""
                />
              </button>
              <div class="uppercase text-xl font-normal">
                Add <span class="font-black">the gamesture store</span> <br>to the home screen
              </div>
              <div class="mt-5 px-6">
                <img
                  v-if="isSafari && !isMobile"
                  :src="HomeScreenSafariDesktop"
                  alt="home screen"
                  class="max-w-80"
                >
                <img
                  v-else-if="!isMobile"
                  :src="HomeScreenDesktop"
                  alt="home screen"
                  class="max-w-80"
                >
                <img
                  v-else
                  :src="HomeScreen"
                  alt="home screen"
                  class="max-w-80"
                >
              </div>
              <table class="text-center text-lg my-4 mx-auto">
                <template v-if="isSafari && !isMobile">
                  <tr>
                    <td class="text-right">
                      Press the
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="octicon:share-24"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      in the Toolbar at the shop
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">
                      Select
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="lucide:dock"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      "Add to Dock"
                    </td>
                  </tr>
                </template>
                <template v-else-if="isSafari && isMobile">
                  <tr>
                    <td class="text-right">
                      Press the
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="octicon:share-24"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      in the Toolbar at the shop
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">
                      Select
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="lets-icons:add-square"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      "Add to Home Screen"
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-right">
                      Press the
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="mage:dots"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      in the Toolbar at the shop
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">
                      Select
                    </td>
                    <td>
                      <div class="bg-neutral-200 rounded w-6 h-7 mx-1 flex justify-center items-center">
                        <icon-ify
                          icon="lets-icons:add-square"
                          class="inline text-xl"
                        />
                      </div>
                    </td>
                    <td class="text-left">
                      "Add to Home Screen"
                    </td>
                  </tr>
                </template>
              </table>

              <el-button
                class="text-white bg-[#3ba6bc] bg-gradient-to-r from-[#3ba6bc] to-[#38c2de] hover:bg-[#3ba6bc] rounded-lg text-xl px-6 tracking-wide uppercase"
                size="large"
                @click="gotIt"
              >
                Got It
              </el-button>
              <div>
                <el-checkbox
                  v-model="checkBoxDisableInstallPrompt"
                  label="Don't show this again"
                  class="text-xl"
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="postcss">
.install-prompt {
  height: 100vh;
  width: 100vw;
  top: 0;
}
.toggle-enter-active {
  animation: toggle-in ease-in 0.5s;
  > * {
    opacity: 1;
  }
}
.toggle-leave-active {
  animation: toggle-in ease-in 0.5s reverse;
  > * {
    transition-property: opacity;
    transition-duration: 150ms;
    opacity: 0;
  }
}
@keyframes toggle-in {
  0% {
    height: 0;
    top: 50%;
  }
  100% {
    height: 100dvh;
    top: 0;
  }
}
.el-checkbox {
  --el-checkbox-checked-text-color: #2d7c8a !important;
  --el-checkbox-checked-bg-color: #2d7c8a !important;
}
</style>
