<script setup lang="ts">
import { logInGameKey, visibilityLogInDialog } from '@/components/layout/LoginDialog/LoginDialog'
import { visibilitySelectorGame } from '@/components/layout/SelectorGame/SelectorGameCompose'

const { list, buyOffer } = gamesUtils
const { isMobileDevice } = tools

function onClosed() {}

const games = Object.values(list)
  .sort((a, b) => a.order - b.order)
  .filter(game => game.active)

function logIn(gameKey: Games.GameKeyType, showLogInDialog = true) {
  buyOffer.value = null
  logInGameKey.value = gameKey
  visibilitySelectorGame.value = false
  visibilityLogInDialog.value = showLogInDialog
}
</script>

<template>
  <el-dialog
    v-model="visibilitySelectorGame"
    modal-class="bg-black/80"
    class="gs-login-dialog rounded-2xl w-11/12 bg-gs-body-bg text-center overflow-hidden big:max-w-4xl"
    destroy-on-close
    :fullscreen="isMobileDevice"
    :show-close="false"
    @closed="onClosed"
  >
    <template #header>
      <div class="text-right pt-3 pr-5">
        <el-button
          size="large"
          class="text-xl stroke-2"
          circle
          @click="visibilitySelectorGame = false"
        >
          <template #icon>
            <icon-ify
              icon="ep:close-bold"
              class="inline"
            />
          </template>
        </el-button>
      </div>
    </template>
    <template #default>
      <div class="text-2xl font-semibold my-5">
        Select game
      </div>
      <div class="bg-gs-body-bg py-1 px-5 big:px-10 mb-16 max-w-md md:max-w-none mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
          <template
            v-for="gameItem in games"
            :key="gameItem.gameKey"
          >
            <template v-if="$store?.getters['game/token'](gameItem.gameKey)?.token?.length">
              <div
                class="border py-3 px-4 min-h-[64px] rounded-lg cursor-pointer opacity-85 hover:opacity-100 active:opacity-90 hover:shadow-md flex justify-between items-center bg-black"
              >
                <router-link
                  :to="gameItem.route.path"
                  @click="visibilitySelectorGame = false"
                >
                  <div class="flex justify-center items-center text-base font-medium text-white">
                    <img
                      alt="game icon"
                      :src="gameItem?.icon"
                      class="w-8 inline-block mr-1.5"
                    >
                    <div><span class="font-black">Logged in </span> to {{ gameItem?.name }}</div>
                  </div>
                </router-link>
              </div>
            </template>
            <template v-else>
              <div
                class="border py-3 px-4 min-h-[64px] rounded-lg cursor-pointer opacity-85 hover:opacity-100 active:opacity-90 hover:shadow-md flex justify-between items-center text-white bg-[#3ba6bc]"
                @click="logIn(gameItem.gameKey)"
              >
                <div class="flex justify-center items-center text-base font-medium">
                  <img
                    alt="game icon"
                    :src="gameItem?.icon"
                    class="w-8 inline-block mr-1.5"
                  >
                  <div>
                    <span class="font-black">Log in </span> to
                    {{ gameItem?.name }}
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
}
</style>
