<script setup lang="ts">
import type { Token } from '@/storeModules/game'
import type { FormInstance } from 'element-plus'
import { logInGameKey, visibilityLogInDialog } from '@/components/layout/LoginDialog/LoginDialog'
import { ElMessage } from 'element-plus'
import { jwtDecode } from 'jwt-decode'

const { buyOffer, triggerApi, checkBuyOfferWithApi } = gamesUtils
const { isMobileDevice } = tools
const offerGameSettings = computed(() => {
  return gamesUtils.getGameSettings(buyOffer.value?.gameKey || gamesUtils.routeGame.value?.gameKey || logInGameKey.value)
})
const route = useRoute()
const showHowMode = ref(false)
const form = ref<{ accountID: string | number, accountToken: string }>({
  accountID: '',
  accountToken: ''
})
const buyMode = ref(true)
const isOpenFromDeepLink = ref(false)
const loading = {
  ref: ref('')
}
const formRef = ref<FormInstance | null>(null)
function beforeClose(done: () => void) {
  if (showHowMode.value) {
    showHowMode.value = false
  } else {
    done()
  }
}

function clearFields() {
  form.value.accountID = ''
  form.value.accountToken = ''
  buyMode.value = false
  isOpenFromDeepLink.value = false
  logInGameKey.value = null
}

const isValidForm = computed(() => {
  return (
    form.value.accountID !== null &&
    /^\d+$/.test(String(form.value.accountID)) &&
    form.value.accountToken !== null &&
    form.value.accountToken?.length
  )
})

function showLastDeepLink() {
  if (import.meta.env.VITE_PROD !== 'on') {
    // eslint-disable-next-line no-alert
    alert(localStorage?.getItem?.('deep-link'))
  }
}

function submitForm(formEl: FormInstance | null) {
  if (!formEl)
    return
  formEl.validate((isValid) => {
    if (!isValid)
      return
    if (offerGameSettings.value?.gameKey) {
      utilsPlugin.getAppGlobalProperties()?.$store?.commit?.('game/logOut', offerGameSettings.value.gameKey)
      loading.ref.value = 'logInDialog'
      const gameKey = offerGameSettings.value.gameKey
      gamesUtils
        .apiPost(gameKey, 'auth/token/', { playerID: form.value.accountID, token: form.value.accountToken }, false)
        .then(({ data }) => {
          if (data.token) {
            let decodedJwt = null
            try {
              decodedJwt = jwtDecode(data.token)
            } catch (err) {
              console?.error?.('not decoded jwt', err, data.token)
            }

            const tokenData = {
              ...{ id: '', name: '', token: '', valid: '', __token: '' },
              ...{
                token: decodedJwt ? data.token : '',
                ...(decodedJwt || {}),
                __token: data.token
              }
            }
            if (offerGameSettings.value?.gameKey) {
              gamesUtils.setGameToken(offerGameSettings.value.gameKey, tokenData as Token)
            }
            if (!gamesUtils.routeGame.value?.gameKey) {
              gamesUtils.triggerApi.callTrigger()
            }
            if (decodedJwt) {
              if (!buyMode.value) {
                ElMessage({
                  message: 'Logged in',
                  type: 'success',
                  customClass: 'left-auto right-10',
                  duration: 3000
                })
              }
              visibilityLogInDialog.value = false
              checkBuyOfferWithApi(loading.ref)
            } else {
              gamesUtils.showError({ message: 'Login failed' })
            }
          } else {
            gamesUtils.showError({ message: 'Login failed' })
          }
        })
        .catch((error) => {
          if (error?.response?.data?.ban) {
            utilsPlugin
              .getAppGlobalProperties()
              ?.$store
              ?.commit?.('game/setLogBan', [gameKey, String(error?.response?.data?.ban)])
          }
          gamesUtils.catchError(error)
        })
        .then(() => {
          loading.ref.value = ''
        })
    }
  })
}

const deepLinkUrl = computed(() => {
  if (offerGameSettings.value?.idApp) {
    // only set when deepLink btn visible;
    localStorage?.setItem?.('buyOffer', JSON.stringify(buyOffer.value))
    return gamesUtils.buildDeepLink(
      offerGameSettings.value.idApp,
      String(route?.name),
      offerGameSettings.value.gameKey,
      String(buyOffer.value?.id)
    )
  }
  return ''
})

const isSupportClipboard = ref(false)

onMounted(() => {
  isSupportClipboard.value = typeof navigator?.clipboard?.readText === 'function'
})
async function paste() {
  if (route.query?.debug) {
    // eslint-disable-next-line no-alert
    alert('Paste data method')
  }
  let paste = ''
  try {
    paste = await navigator?.clipboard?.readText?.()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error', err)
  }
  if (paste?.length) {
    const pasteSplit = paste.split(' ')
    if (pasteSplit.length === 2) {
      form.value.accountID = Number.isNaN(Number.parseInt(pasteSplit[0])) ? pasteSplit[0] : Number.parseInt(pasteSplit[0])
      form.value.accountToken = pasteSplit[1]
      formRef?.value?.validate?.()
    }
  }
}
async function onPaste(ev: ClipboardEvent) {
  let pastedData = ''
  if (ev.clipboardData) {
    pastedData = ev.clipboardData?.getData?.('Text') || ''
  }
  if (!pastedData.length && isSupportClipboard.value) {
    pastedData = await navigator?.clipboard?.readText?.()
  }
  if (pastedData?.length) {
    const checkVal = pastedData.split(' ')
    if (checkVal.length === 2) {
      ev.stopPropagation()
      ev.preventDefault()
      form.value.accountID = Number.isNaN(Number.parseInt(checkVal[0])) ? checkVal[0] : Number.parseInt(checkVal[0])
      form.value.accountToken = checkVal[1]
      formRef?.value?.validate?.()
    }
  }
}
const accountIDElement = ref<HTMLInputElement | null>(null)
function focusAccountIDElement() {
  utilsPlugin.nextLoopEvent(100).then(() => {
    accountIDElement?.value?.focus()
  })
}
watch(
  () => visibilityLogInDialog.value,
  () => {
    buyMode.value = !!buyOffer.value?.id
    if (buyOffer.value?.deepLink) {
      buyOffer.value.deepLink = false
      isOpenFromDeepLink.value = true
    }
    if (
      visibilityLogInDialog.value &&
      !showHowMode.value &&
      !(buyMode.value && ((isMobileDevice.value && !isOpenFromDeepLink.value) || !isMobileDevice.value))
    ) {
      focusAccountIDElement()
    }
  }
)
function showForm() {
  buyMode.value = false
  focusAccountIDElement()
}

const logBanMinutes = ref(0)

function checkBan() {
  const banUntil = utilsPlugin.getAppGlobalProperties()?.$store?.getters?.['game/logBan']?.(offerGameSettings.value?.gameKey)
  if (!banUntil) {
    logBanMinutes.value = 0
  }
  const diff = new Date(banUntil * 1000).getTime() - new Date().getTime()
  if (diff > 0) {
    logBanMinutes.value = Math.ceil(diff / 1000 / 60)
  } else {
    logBanMinutes.value = 0
  }
}

watch(
  () => utilsPlugin.getAppGlobalProperties()?.$store?.getters?.['game/logBan']?.(offerGameSettings.value?.gameKey),
  () => {
    checkBan()
  }
)
watch(triggerApi.triggerRef, () => {
  checkBan()
})
</script>

<template>
  <el-dialog
    v-model="visibilityLogInDialog"
    align-center
    modal-class="bg-black/80"
    class="gs-login-dialog rounded-2xl w-11/12 bg-white text-center overflow-hidden"
    :class="{ 'big:max-w-2xl': !showHowMode, 'big:max-w-3xl': showHowMode }"
    destroy-on-close
    :before-close="beforeClose"
    @close="clearFields()"
    @paste="paste"
    @open="checkBan"
  >
    <template #header>
      <div class="uppercase text-2xl flex justify-center items-center tracking-wider font-extrabold p-5 bg-gs-body-bg">
        <div class="text-gs-bg">
          {{ offerGameSettings?.name }}
        </div>
        <el-image
          :src="offerGameSettings?.icon"
          class="rounded-xl shadow-md shadow-gs-bg w-14 mx-4"
          @click="showLastDeepLink"
        />
      </div>
    </template>
    <template #default>
      <div class="bg-white pt-3 big:pt-5 px-5 big:px-10 max-w-lg big:max-w-none mx-auto">
        <div class="font-extrabold text-2xl uppercase">
          <span v-if="showHowMode">How to</span> Log in
        </div>
        <template v-if="buyMode && ((isMobileDevice && !isOpenFromDeepLink) || !isMobileDevice)">
          <div class="mt-5 text-xl">
            To continue shopping, you must log in to your {{ offerGameSettings?.name }} game account. You'll also see more offers,
            including offers just for you.
          </div>
          <div class="my-5">
            <el-button
              color="#3ba6bc"
              class="text-white rounded-lg uppercase text-xl mb-5"
              size="large"
              style="--el-button-size: 54px"
              @click="showForm"
            >
              Log in
            </el-button>
          </div>
        </template>
        <template v-else-if="showHowMode">
          <div class="mx-1 big:mx-4 mt-5 mb-16 flex flex-wrap">
            <ul class="text-left list-decimal text-sm big:text-base font-medium big:w-1/2 mb-5">
              <li>Open a {{ offerGameSettings?.name }} game on you mobile device</li>
              <li>Find in-game Settings</li>
              <li>Find section with PlayerID and Token</li>
              <li>You can copy the above data to the clipboard using the Copy button</li>
              <li>Return to the store and enter the above data into the form (paste or enter manually)</li>
              <li>Click confirm</li>
            </ul>
            <div
              v-if="offerGameSettings?.logInImg?.length"
              class="big:w-1/2"
            >
              <el-image
                width="710"
                height="768"
                class="px-2 mt-2"
                :src="offerGameSettings?.logInImg[0]"
                :zoom-rate="0"
                :max-scale="1"
                :min-scale="1"
                :preview-src-list="offerGameSettings?.logInImg"
                fit="cover"
              />
              <div class="text-sm text-neutral-150 w-full italic tracking-wide">
                preview
              </div>
            </div>
            <div class="clear-both w-full">
              <el-button
                color="#3ba6bc"
                class="text-white rounded-lg uppercase text-xl mt-5"
                size="large"
                style="--el-button-size: 54px"
                @click="showHowMode = false"
              >
                back to form
              </el-button>
            </div>
          </div>
        </template>
        <div
          v-else-if="logBanMinutes"
          class="mt-5 mb-16 text-xl"
        >
          Too many failed attempts. Please wait {{ logBanMinutes }} minute{{ logBanMinutes > 1 ? 's' : '' }} before trying again.
        </div>
        <template v-else>
          <div class="my-3 big:my-5">
            <div
              v-if="deepLinkUrl.length && isMobileDevice"
              class="mt-2"
            >
              <template v-if="!isOpenFromDeepLink">
                <a
                  :href="deepLinkUrl"
                >
                  <el-button
                    color="#3ba6bc"
                    class="text-white rounded-lg uppercase text-xl mb-2 big:mb-5 min-w-64"
                    size="large"
                    style="--el-button-size: 54px"
                  >
                    Log in via app
                  </el-button>
                </a>
                <div class="leading-none text-[0.7rem] text-neutral-400 my-1">
                  This method requires the game to be installed on your device. Not all browsers support this method. It is recommended to run the game first.
                </div>
                <div class="font-black text-lg tracking-wider mt-3 mb-2">
                  OR
                </div>
              </template>
            </div>

            <div
              class="text-sm big:text-xl"
              :class="{ 'mt-2 big:mt-5': !(deepLinkUrl.length && isMobileDevice) }"
            >
              <span v-if="deepLinkUrl.length && isMobileDevice && !isOpenFromDeepLink">Enter</span>
              <span v-else>To log in to the game, you need to enter</span>
              the Player ID and Game Token, which you can find in the game settings. [<a
                href="#"
                class="text-gs-main-color text-base big:text-xl font-bold hover:text-cyan-600 inline"
                @click="showHowMode = true"
              >Click here</a>] to see exactly where you can find this information in the game.
            </div>
            <el-form
              ref="formRef"
              label-position="top"
              :model="form"
              size="default"
              class="big:mx-3 mt-2"
              hide-required-asterisk
            >
              <el-form-item
                label="Game PlayerID"
                prop="accountID"
                :rules="[
                  { required: true, message: 'Please enter game PlayerID', trigger: 'blur' },
                  { type: 'number', message: 'PlayerID must be a number' },
                  { type: 'number', min: 1, message: 'Only positive numbers', trigger: 'change' }
                ]"
              >
                <el-input
                  ref="accountIDElement"
                  v-model.number="form.accountID"
                  class="text-xl"
                  clearable
                  @paste.stop="onPaste"
                />
              </el-form-item>
              <el-form-item
                label="Game Token"
                prop="accountToken"
                :rules="[{ required: true, message: 'Please enter game Game Token', trigger: 'blur' }]"
              >
                <el-input
                  v-model="form.accountToken"
                  class="text-xl"
                  clearable
                  @paste.stop="onPaste"
                  @keyup.enter="submitForm(formRef)"
                />
              </el-form-item>
              <div
                v-if="isSupportClipboard"
                class="text-right -mt-3"
              >
                <el-button
                  text
                  class="text-gs-main-color"
                  @click="paste"
                >
                  - paste the data -
                </el-button>
              </div>
              <el-button
                :loading="loading.ref.value === 'logInDialog'"
                :color="isValidForm ? '#3ba6bc' : '#c0c0c0'"
                class="text-white rounded-lg uppercase text-xl mb-2 big:mb-5 min-w-64"
                size="large"
                style="--el-button-size: 54px"
                @click="submitForm(formRef)"
              >
                confirm
              </el-button>
            </el-form>
          </div>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
  .el-input__inner {
    --el-input-inner-height: 48px;
  }
  .el-image-viewer__actions {
    display: none !important;
  }
}
</style>
