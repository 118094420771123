<script setup lang="ts">
import { offerDialog, showOfferUrl } from '@/components/shared/OfferDialog/OfferDialogCompose'
import OfferPage from '@/pages/game/OfferPage.vue'

const { triggerApi } = gamesUtils
const { getAppGlobalProperties } = utilsPlugin
const { isDesktop } = tools
const route = useRoute()
const router = useRouter()

const backRoute = ref('')
watch(
  () => offerDialog.value.visible,
  () => {
    if (offerDialog.value.visible) {
      backRoute.value = getAppGlobalProperties()?.$route?.fullPath || ''
      if (getAppGlobalProperties()?.$route.hash !== '#') {
        backRoute.value += '#'
      }
      showOfferUrl()
    } else {
      const router = utilsPlugin.getAppGlobalProperties()?.$router
      if (router) {
        router.replace(backRoute.value)
      }
      backRoute.value = ''
    }
  }
)
function onClose() {
  triggerApi.callTrigger()
}

if (route.query?.offerGameId && route.query?.offerId) {
  offerDialog.value.visible = false
  router.push({
    name: 'game-product-page',
    params: {
      gameID: String(route.query.offerGameId),
      offerID: String(route.query.offerId)
    }
  })
}
</script>

<template>
  <el-dialog
    v-model="offerDialog.visible"
    top="0px"
    :modal-class="isDesktop ? 'bg-black/80' : 'bg-white'"
    :class="isDesktop ? 'bg-transparent' : 'bg-white mb-0'"
    width="100%"
    style="--el-dialog-padding-primary: 0"
    destroy-on-close
    :show-close="false"
    @closed="onClose"
  >
    <template #default>
      <div @click="offerDialog.visible = false">
        <div class="mx-auto max-w-xl big:max-w-2xl px-4 big:px-2 pt-1 pb-3 text-right">
          <el-button
            size="large"
            class="text-xl stroke-2"
            circle
          >
            <template #icon>
              <icon-ify
                icon="ep:close-bold"
                class="inline"
              />
            </template>
          </el-button>
        </div>
        <div
          class="relative rounded-2xl w-[99%] big:max-w-2xl bg-white text-center mx-auto pt-2 big:px-4"
          @click.stop
        >
          <OfferPage
            :key="offerDialog.offer?.id"
            :modal-offer="offerDialog.offer"
          />
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="postcss"></style>
