<script setup lang="ts">
import { buyFreeDialog } from '@/components/layout/BuyFreeDialog/BuyFreeDialogCompose'

const offerGameSettings = computed(() => {
  return gamesUtils.getGameSettings(buyFreeDialog.value?.offer?.gameKey)
})
function onClosed() {
  buyFreeDialog.value.offer = null
}
</script>

<template>
  <el-dialog
    v-model="buyFreeDialog.visible"
    align-center
    modal-class="bg-black/80"
    class="gs-login-dialog rounded-2xl w-11/12 bg-white text-center overflow-hidden big:max-w-3xl"
    destroy-on-close
    @closed="onClosed"
  >
    <template #header>
      <div class="text-2xl flex justify-start items-center font-extrabold p-5 bg-gs-body-bg">
        <el-image
          v-if="offerGameSettings?.icon"
          :src="offerGameSettings?.icon"
          class="rounded-xl shadow-md shadow-gs-bg w-14 mx-4"
        />
        <div class="text-gs-bg">
          Confirmation
        </div>
      </div>
    </template>
    <template #default>
      <div class="bg-white py-10 px-5 big:px-10">
        <div class="text-2xl my-5">
          The purchase for this offer has been completed successfully. Kindly claim and enjoy the benefits in your game.
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="postcss">
.gs-login-dialog {
  --el-message-close-size: 1.5rem;
  --el-dialog-padding-primary: 0.1;
  .el-dialog {
    &__header {
      margin-right: 0 !important;
    }
  }
}
</style>
