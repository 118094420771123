<script setup lang="ts">
const modelValue = defineModel()
</script>

<template>
  <div
    class="inline-block px-5"
    @click="modelValue = !modelValue"
  >
    <div
      class="h-btn"
      :class="{ open: modelValue }"
    >
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.h-btn {
  width: 1em;
  height: 0.85em;
  position: relative;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 0.1em;
    width: 100%;
    background: currentColor;
    border-radius: 9px;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      visibility: hidden;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 0.3em;
    }

    &:nth-child(4) {
      top: 0.6em;
    }
  }

  &.open {
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    transition-delay: 0.1s;

    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    span {
      &:nth-child(1) {
        top: 0.3em;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        visibility: visible;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 0.3em;
        width: 0%;
        left: 50%;
      }
    }
  }
}
</style>
